
.resume-box {
    margin-top: 10vh;
}

.card {
    background-color: lightcyan;
    border: none;
}

.resume-body {
    border-left: 3px solid var(--dark);
}

.card-body {
    display: flex;
    flex-direction: column;
    background-color: whitesmoke;
    box-shadow: 5px 5px 15px slategray;
}

.card-title, .card-subtitle, .card-text, ul, li, span, em {
    background-color: transparent;
}

.card-title {
    font-weight: bold;
}

.card-text {
    margin: 0;
}

hr {
    width: 70%;
    align-self: center;
}

.card a {
    align-self: center;
  }

.card a .btn {
background-color: var(--dark);
color: var(--light);
font-weight: bold;
}

@media screen and (max-width: 992px){
    .sb  {
        border-bottom: 3px solid var(--dark);
    }
    .resume-body {
        border:none;
    }
}