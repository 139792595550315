.contact-box {
    display: flex;
    margin-top: 10vh;
}

.com {
    font-size: 1.5rem;
    margin-bottom: 30px;
}

.contact-link:link, .contact-link:visited {
    align-self: flex-start;
    padding: 0 5px;
    color: var(--dark);
    text-decoration:none;
    background-color: var(--light);
}
.contact-link:hover {
    color: var(--light);
    background-color: var(--dark);
}

/* h1.sb {
    font-size: 3em;
    font-weight: lighter;
    width: 225px;
    display: flex;
    justify-content: flex-end;
    text-align: right;
    align-self: stretch;
    padding: 3vh 3vw;
} */

.contact-form {
    display: flex;
    flex-direction: column;
    border-left: 3px solid var(--dark);
    padding: 20px;
    width:   60%
}

.contact-form p {
    font-style: italic;
    text-align: center;
}

.contact-form .btn {
    align-self: center;
    background-color: var(--dark);
    color: var(--light);
    font-weight: bold;
  }

  .form-alert-box {
      display: flex;
      justify-content: space-between;
  }

  .form-alert {
      font-size: 0.8rem;
      color: red;
      font-weight: bold;
  }


