.modal-dialog {
    background-color: whitesmoke;
    height: 60%;
    margin-top: 15vh;
  }
  .modal-header {
    background-color: var(--light);
    text-align: center;
    flex-direction: column;
  }
  .modal-body {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: whitesmoke;
  }
  .modal-body a {
    text-decoration: none;
    margin: 0 15px;
    font-size: 1.25em;
    font-weight: bold;
    color: var(--dark);
    background-color: transparent;
    border-bottom: 2px solid var(--dark);
  }
  .modal-body a:hover,
  .modal-body a:active {
      box-shadow: inset 0px 0px 10px 1px magenta;
  }
  
  .modal-body a:visited {
      text-decoration: none;
      color: var(--dark);
  }
  .modal-body img {
    border: 5px solid var(--light)
  }
  .modal-body .img-card{
    padding: 5px;
    background-color: var(--dark);
    margin: 10px;
  }
  .modal-body {
    display: flex;
    background-color: transparent;
  }
  .modal-body p {
    text-align: center;
    background-color: transparent;
  }
  .btn {
    background-color: var(--dark);
    color: var(--light);
    font-weight: bold;
  }
  .modal-footer {
    background-color: var(--light);
  }
  .modal-content {
    box-shadow: 5px 5px 15px slategray;
  }


