

h1.sb {
    font-size: 3em;
    font-weight: lighter;
} 

div.AM-p {
    padding: 0 3vw;
    font-size: 1.25em;
    border-left: 3px solid var(--dark);
}

@media screen and (max-width: 992px){
    .sb  {
        border-bottom: 3px solid var(--dark);
    }
    div.AM-p, .proj-boxes {
        border:none;
    }
    div.AM-p p {
        text-align: center;
    }
}