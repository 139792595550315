/********Header********/
.navbar, .nav-cont, a.navbar-brand, .navbar-collapse   {
    background-color: var(--dark);
}

button.navbar-toggler.collapsed, .navbar-toggler-icon {
    background-color: var(--light);

}

button.navbar-toggler.collapsed{    
    margin-right: 10px;
}

h1.Header-top {
    display: flex;
    justify-content: center;
    align-content: center;
    align-self: flex-start;
    width: auto;

    padding: 1vh 2vw;
    font-weight: 700;
    font-size: 3.5em;
    background-color: var(--light);
    color: var(--dark);
}

div.Header-avatar {
    height: 45px;
    width: 45px;
    background-color: transparent;
    margin: 0 10px;
}

div.Header-avatar img {
    height: 45px;
    width: 45px;
    border: 2px solid var(--dark);
}

.Header-nav {
    background-color: transparent;
    color: var(--light);
    /* width:100%; */
    align-self:center;
    /* justify-content: space-around; */
    display: flex;
    flex-flow: row wrap;
}

.Header-a {
    align-self: center;
    margin: 2vw;
    background-color: transparent;
    color: var(--light);
    border-bottom: 2px solid rgb(130, 245, 245);
}

.Header-a:link {
    text-decoration: none;
    color: var(--light);
}

.Header-a:hover,
.Header-a:active {
    box-shadow: inset 0px 0px 10px 5px var(--highlight) !important;
}

.Header-a:visited {
    text-decoration: none;
    color: var(--light);
}

.clicked {
    align-self: center;
    margin: 2vw;
    background-color: transparent;
    color: var(--light);
    border-bottom: 2px solid rgb(130, 245, 245);
    box-shadow: inset 0px 0px 10px 5px var(--highlight);
}

div.Header-bi1 {
    justify-content: flex-end;
    height: 7em;
    background: url(./asset/images/wall-bluegreen.jpg) no-repeat lightseagreen;
    background: cover;
}


h2.Header-top{
    display: flex;
    justify-content: center;
    align-items: center;
    /* align-self: flex-start; */
    width: auto;
    margin: 2vh 5vw;
    padding: 0 2vw;
    font-weight: 700;
    font-size: 2em;
    background-color: var(--light);
    color: var(--dark);
}

/***********Main*************/
main, .app-box {
    height: 100%;
    border-top: 1px solid black;
    background: url(./asset/images/wall-bluegreen.jpg)lightseagreen;
    background: cover;
}

section{
    margin: 175px 0 300px 0;
    padding:30px;
}
/* main {
    position: absolute;
    width: 100%;
    top: 12vh;
    overflow: auto;
    height: 100%;
} */

/********Footer*********/
/* footer {
    width: 100%;
    height: 10vh;
    display: flex;
    align-content: center;
    position: fixed;
    bottom: 0;
    overflow: hidden; */
    /* border:1px solid black; */
/* } */

.foot {
    background-color: var(--light);
    padding: 10px
}

.Footer-title {
    background-color: transparent;
}

.Footer-nav {
    width: 100%;
    display: flex;
    /* align-items: flex-end; */
    justify-content: center;
    flex-flow: row wrap;
    margin-left: 3em;
}


.Footer-a {
    align-self: center;
    margin: 0 15px;
    font-size: 1.25em;
    font-weight: bold;
    color: var(--dark);
    border-bottom: 2px solid var(--dark);
}

.Footer-a:link {
    text-decoration: none;
    color: var(--dark);
}

.Footer-a:hover,
.Footer-a:active {
    box-shadow: inset 0px 0px 10px 1px var(--highlight);
}

.Footer-a:visited {
    text-decoration: none;
    color: var(--dark);
}

@media screen and (max-width: 992px) {
    h1.Header-top {
        color: var(--light);
        background: url(./asset/images/wall-bluegreen.jpg) no-repeat lightseagreen;
        background: cover;
    }
}