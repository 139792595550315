/* h1.sb {
    font-size: 3em;
    font-weight: lighter;
    width: 225px;
    display: flex;
    justify-content: flex-end;
    text-align: right;
    align-self: stretch;
    padding: 3vh 2vw;
} */
.proj-boxes {
    display: flex;
    border-left: 3px solid var(--dark); 
    padding-left: 3vw;
}

.proj-card {
    width: 200px;
    height: 190px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: var(--light);
    margin: 30px
}

.proj-card-img{
    width: 90%;
    height: 90%;
    display: flex;
    align-items: flex-end;
    justify-content: center;
    background-size: cover;
    background-position: center;
}

.proj-card-img:hover{
    box-shadow: inset 0px 0px 70px 5px var(--highlight);
}


.proj-place-holder {
    width: 90%;
    height: 90%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.proj-ph-text {
    font-size: 2rem;
    background-color: transparent;
    color: var(--light);
    font-weight: bold;
    transform: rotate(-25deg);
    opacity: 0.60;
}

.proj-text-box {
    padding: 5px;
    background-color: var(--dark);
    margin: 10px;
    opacity: 0;
    transition: opacity 500ms;
    border: 2px solid var(--light);
}

.proj-card-img:hover .proj-text-box {
    opacity: 1
}

.proj-text {
    font-size: 1.25rem;
    background-color: var(--dark);
    color: var(--light);
    font-weight: bold;
    background-color: transparent;
    padding: 0;
    line-height: 1.5rem;
    margin: 0;
}

.proj-subtext {
    font-size: 0.70rem;
    font-style: italic;
    background-color: var(--dark);
    color: var(--light);
    font-weight: bold;
    background-color: transparent;
    padding:0;
    margin:0;
    line-height: 1rem;

}


@media screen and (max-width: 992px){
    .sb  {
        border-bottom: 3px solid var(--dark);
    }
    .proj-boxes {
        border:none;
    }
}